import type { PageProps } from 'gatsby';
import React from 'react';

import {
  SectionFeatures,
  SectionFirstScreen,
  SectionInscription,
  Seo
} from '@/components';

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <div id="FirstScreen">
        <SectionFirstScreen />
      </div>
      <SectionFeatures />
      <SectionInscription />
    </>
  );
};
export const Head = () => <Seo title="" />;
export default IndexPage;
