import cn from 'classnames';
import Lottie from 'lottie-react';
import React from 'react';
import { useInView } from 'react-intersection-observer';

interface IUseLottieInViewProps {
  animationData: unknown;
  className?: string;
  inViewCheck?: boolean;
  isLoop?: boolean;
}
export function LottieInView(props: IUseLottieInViewProps) {
  const {
    animationData,
    className,
    inViewCheck = false,
    isLoop = true
  } = props;
  const { ref: containerRef, inView: containerInView } = useInView();
  if (inViewCheck) {
    return (
      <div className={cn(className)} ref={containerRef}>
        {containerInView && (
          <Lottie animationData={animationData} autoplay={true} loop={isLoop} />
        )}
      </div>
    );
  }
  return <Lottie animationData={animationData} autoplay={true} loop={isLoop} />;
}
