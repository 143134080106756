import cn from 'classnames';
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

import multichainLottie from '@/assets/lottie/multichain.json';
import playLottie from '@/assets/lottie/play.json';

import { LottieInView } from '../../common/LottieInView';

export function SectionFeatures() {
  return (
    <div className="page-container pt-[88px] md:pt-[122px]">
      <Fade>
        <Slide direction="up">
          <div className="text-center text-3xl uppercase md:text-[54px] md:leading-[1.2]">
            The first inscription game collection on the EVM blockchains
          </div>
        </Slide>
      </Fade>

      <div className={cn('mt-[90px] md:px-[40px]')}>
        <div className={cn('mt-8 md:flex md:justify-between')}>
          <div className="relative w-full rounded-[30px] bg-[#212239] pt-[100%] md:w-[62%] md:pt-[62%]">
            <div className="absolute left-0 top-0 h-full w-full rounded-[30px]">
              <LottieInView
                className="w-full"
                isLoop={true}
                animationData={playLottie}
              />
            </div>
          </div>
          <div className="hidden w-[36%] md:block">
            <div className="sticky top-[300px] w-full rounded-[30px] border-[1px] border-[#282635] bg-gradient-to-b from-[#1B2437] to-[#1A233500] pt-[100%]">
              <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-between rounded-[30px] p-[18px]">
                <div className="font-BaiJamjuree text-[16px] font-[400] text-[#88859E]">
                  ECONOMICS
                </div>
                <div>
                  <div className="text-[32px] leading-[40px]">Play to Mint</div>
                  <div className="mt-[13px] font-BaiJamjuree text-[16px] text-[#B1BECB]">
                    Engage in games and claim your own token after clearing the
                    levels.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 block md:hidden">
            <div className="font-BaiJamjuree text-[16px] font-[400] text-[#88859E]">
              ECONOMICS
            </div>
            <div className="text-[20px] leading-[28px]">PLAY TO EARN</div>
            <div className="font-BaiJamjuree text-[16px] text-[#B1BECB]">
              Engage in games and claim your own token after clearing the
              levels.
            </div>
          </div>
        </div>

        <div className="mt-8 md:flex md:justify-between">
          <div className="hidden w-[36%] md:block">
            <div className="sticky top-[300px] w-full rounded-[30px] border-[1px] border-[#282635] bg-gradient-to-b from-[#1B2437] to-[#1A233500] pt-[100%]">
              <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-between rounded-[30px] p-[18px]">
                <div className="font-BaiJamjuree text-[16px] font-[400] text-[#88859E]">
                  ECOSYSTEM
                </div>
                <div>
                  <div className="text-[32px] leading-[40px]">
                    More Classic & Meme Games on Multiple Chains
                  </div>
                  <div className="mt-[13px] font-BaiJamjuree text-[16px] text-[#B1BECB]">
                    More classic meme games like agar.io, merge fruits & flappy
                    birds incoming served as a part of the Dice Collection.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'relative  w-full rounded-[30px] bg-[#212239] pt-[100%] md:w-[62%] md:pt-[62%]'
            }
          >
            <div className="absolute left-0 top-0 h-full w-full rounded-[30px]">
              <LottieInView
                className="w-full"
                isLoop={true}
                animationData={multichainLottie}
              />
            </div>
          </div>

          <div className="mt-2 block md:hidden">
            <div className="font-BaiJamjuree text-[16px] font-[400] text-[#88859E]">
              ECOSYSTEM
            </div>
            <div className="text-[20px] leading-[28px]">
              More Classic & Meme Games on Multiple Chains
            </div>
            <div className="font-BaiJamjuree text-[16px] text-[#B1BECB]">
              More classic meme games like agar.io, merge fruits & flappy birds
              incoming served as a part of the Dice Collection.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
