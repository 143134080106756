import { CHAIN_ENV } from 'gatsby-env-variables';
import React, { ReactNode } from 'react';
import { createPublicClient, http } from 'viem';
import { bsc, bscTestnet, polygon } from 'viem/chains';
import { configureChains, createConfig } from 'wagmi';
import { WagmiConfig } from 'wagmi';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';

import { initializeEIP6963 } from '@/walletconnect/mipd';

export const targetChain = CHAIN_ENV === 'testnet' ? polygon : bsc;
const { chains, publicClient } = configureChains(
  [targetChain],
  [publicProvider()]
);

export const gasClient = createPublicClient({
  chain: targetChain,
  transport: http()
});

export const connectorConfig = createConfig({
  connectors: [
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '1f1519981d9cd8e15ae523938ed3ffd6',
        qrModalOptions: {},
        metadata: {
          name: '2048.ink',
          description: '2048.ink',
          url: 'https://2048.evm.ink/',
          icons: ['https://wagmi.sh/icon.png']
        }
      }
    })
  ],
  publicClient
});
interface IWagmiProviderProps {
  children: ReactNode;
}

initializeEIP6963();

export function WagmiProvider(props: IWagmiProviderProps) {
  const { children } = props;
  return <WagmiConfig config={connectorConfig}>{children}</WagmiConfig>;
}
