import { navigate } from 'gatsby';
import React, { useCallback, useMemo } from 'react';
import { useConnect, useDisconnect, useSwitchNetwork } from 'wagmi';

import MetaMaskSVG from '@/assets/images/2048/metamask.inline.svg';
import OKXSVG from '@/assets/images/2048/okx.inline.svg';
import RainbowSVG from '@/assets/images/2048/rainbow.inline.svg';
import TPSVG from '@/assets/images/2048/tp.inline.svg';
import TrustSVG from '@/assets/images/2048/trust.inline.svg';
import WalletConnectSVG from '@/assets/images/2048/wallet_connect.inline.svg';
import { Loading } from '@/components/common/Loading';
import { targetChain } from '@/components/common/WagmiProvider';
import { ERouterPaths, EWalletTypes, WalletIDMap } from '@/utils/const';

import { Modal } from './Modal';
interface IConnectWalletProps {
  onClose?: () => void;
}
export function ConnectWallet({ onClose }: IConnectWalletProps) {
  // 设置chainid以后，connect的时候会自动add and switch to chain
  const { switchNetwork } = useSwitchNetwork();
  const { connectors, isLoading, pendingConnector, connect } = useConnect({
    chainId: targetChain.id,
    onError: (e) => {
      console.error(e);
      disconnect();
    },
    onSuccess: (e) => {
      if (e.chain.id !== targetChain.id && switchNetwork) {
        switchNetwork(targetChain.id);
      }
    }
  });
  const { disconnect } = useDisconnect();
  const onConnectWallet = useCallback(
    async (type: EWalletTypes) => {
      if (isLoading) {
        return;
      }
      const connectorId = WalletIDMap[type];
      const walletConnector = connectors.find(
        (item) => item.id === connectorId
      );
      if (!walletConnector) {
        return;
      }
      connect({
        connector: walletConnector
      });
    },
    [connect, connectors, isLoading]
  );
  const wallets = useMemo(
    () => [
      {
        icon: MetaMaskSVG,
        title: 'Metamask',
        loading:
          isLoading &&
          pendingConnector?.id === WalletIDMap[EWalletTypes.METAMASK],
        onClick: () => {
          onConnectWallet(EWalletTypes.METAMASK);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.METAMASK]
        )
      },
      {
        icon: TrustSVG,
        title: 'Trust',
        loading:
          isLoading && pendingConnector?.id === WalletIDMap[EWalletTypes.TRUST],
        onClick: () => {
          onConnectWallet(EWalletTypes.TRUST);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.TRUST]
        )
      },
      {
        icon: OKXSVG,
        title: 'OKX',
        loading:
          isLoading && pendingConnector?.id === WalletIDMap[EWalletTypes.OKX],
        onClick: () => {
          onConnectWallet(EWalletTypes.OKX);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.OKX]
        )
      },
      {
        icon: TPSVG,
        title: 'Token Pocket',
        loading:
          isLoading &&
          pendingConnector?.id === WalletIDMap[EWalletTypes.TOKEN_POCKET],
        onClick: () => {
          onConnectWallet(EWalletTypes.TOKEN_POCKET);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.TOKEN_POCKET]
        )
      },
      {
        icon: WalletConnectSVG,
        title: 'Wallet Connect',
        loading:
          isLoading &&
          pendingConnector?.id === WalletIDMap[EWalletTypes.WALLET_CONNECT],
        onClick: () => {
          onConnectWallet(EWalletTypes.WALLET_CONNECT);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.WALLET_CONNECT]
        )
      },
      {
        icon: RainbowSVG,
        title: 'Rainbow',
        loading:
          isLoading &&
          pendingConnector?.id === WalletIDMap[EWalletTypes.RAINBOW],
        onClick: () => {
          onConnectWallet(EWalletTypes.RAINBOW);
        },
        isInstalled: !!connectors.find(
          (item) => item.id === WalletIDMap[EWalletTypes.RAINBOW]
        )
      }
    ],
    [connectors, isLoading, onConnectWallet, pendingConnector?.id]
  );
  return (
    <Modal
      title="Connect Wallet"
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          navigate(ERouterPaths.LEARN_MORE);
        }
      }}
      className="!absolute top-1/2 -translate-y-1/2"
    >
      <div className="-mt-4 flex w-full flex-col gap-y-2 sm:mb-6 sm:mt-0 sm:gap-y-3">
        {wallets
          .filter((item) => item.isInstalled)
          .map((wallet) => (
            <div
              key={wallet.title}
              onClick={wallet.onClick}
              className="relative flex h-10 w-full cursor-pointer items-center gap-x-2 rounded-md border-[1px] border-solid border-transparent bg-[#313A48] px-6 text-base font-medium text-[#E5ECF4] duration-200 hover:opacity-75 sm:h-14 sm:rounded-[10px] sm:text-lg"
            >
              <wallet.icon className="mr-1 w-6 origin-center sm:w-8" />
              {wallet.title}
              {wallet.loading && (
                <Loading className="absolute right-4 top-1/2 -translate-y-1/2" />
              )}
            </div>
          ))}
      </div>
    </Modal>
  );
}
