/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { AbiCoder } from 'ethers';
import { navigate } from 'gatsby';
import { isObject } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSearchParam, useUnmount } from 'react-use';
import { parseEther } from 'viem/utils';
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi';

import { Button, Modal } from '@/components';
import { gasClient, targetChain } from '@/components/common/WagmiProvider';
import { EMintType, MintTypePSearchParamKey } from '@/pages/games/2048';
import { useAppStore } from '@/store/appStore';
import { ellipseAddress } from '@/utils/common';
import { currentContractConfig, ERouterPaths } from '@/utils/const';

import { Split } from '../../../common/Split';
import { AmountInput } from './AmountInput';
import { MintSuccess } from './MintSuccess';
import { ModalLine } from './ModalLine';

interface IMintModalProps {
  className?: string;
}

export function MintModal(props: IMintModalProps) {
  const { className } = props;
  const {
    gameSeed,
    score,
    playMintSignature: signature,
    setPlayMintSignature,
    setGameMoves
  } = useAppStore();
  const { address } = useAccount();
  const mintType = useSearchParam(MintTypePSearchParamKey);
  const [mintAmount, setMintAmount] = useState(1);
  const isMintDirectly = useMemo(
    () => mintType === EMintType.DIRECTLY,
    [mintType]
  );

  const price = useMemo(
    () =>
      isMintDirectly
        ? currentContractConfig.unitPrice
        : currentContractConfig.unitPrice,
    [isMintDirectly]
  );
  const abi = useMemo(
    () =>
      isMintDirectly
        ? [
            {
              inputs: [
                {
                  internalType: 'uint256',
                  name: '_count',
                  type: 'uint256'
                }
              ],
              name: 'batchMint',
              outputs: [],
              stateMutability: 'payable',
              type: 'function'
            }
          ]
        : [
            {
              inputs: [
                {
                  internalType: 'uint256',
                  name: '_score',
                  type: 'uint256'
                },
                {
                  internalType: 'uint256',
                  name: '_seed',
                  type: 'uint256'
                },
                {
                  internalType: 'bytes',
                  name: '_signature',
                  type: 'bytes'
                }
              ],
              name: 'mintWithScore',
              outputs: [],
              stateMutability: 'payable',
              type: 'function'
            }
          ],
    [isMintDirectly]
  );

  const mintArgs = useMemo(() => {
    return isMintDirectly
      ? [mintAmount]
      : [score || 0, gameSeed, String(signature)];
  }, [gameSeed, isMintDirectly, mintAmount, score, signature]);

  const [gas, setGas] = useState<bigint>(BigInt(0));
  const [gasPrice, setGasPrice] = useState<bigint>(BigInt(0));

  const [isEstimateGasErrorByLowBalance, setEstimateGasErrorByLowBalance] =
    useState(false);
  const [isFetchingGas, setFetchingGas] = useState(true);
  useEffect(() => {
    if (!address) return;
    async function updateGas() {
      setFetchingGas(true);
      try {
        const gas = await gasClient.estimateContractGas({
          account: address as `0x${string}`,
          address: currentContractConfig.contractAddress as `0x${string}`,
          abi,
          functionName: abi[0].name,
          args: mintArgs,
          value: parseEther((price * mintAmount).toString(), 'wei')
        });
        setGas(gas * BigInt(2));
        setEstimateGasErrorByLowBalance(false);
      } catch (e: any) {
        console.error(e);
        if (isObject(e)) {
          const message = (e as any)?.message as string;
          if (
            message.toLowerCase().includes('exceeds the balance of the account')
          ) {
            setEstimateGasErrorByLowBalance(true);
          }
        }
      }
      setFetchingGas(false);
    }
    updateGas();
  }, [abi, address, mintAmount, mintArgs, price]);

  const [isFetchingGasPrice, setFetchingGasPrice] = useState(true);
  useEffect(() => {
    if (!address) return;
    async function updateGasPrice() {
      setFetchingGasPrice(true);
      try {
        const price = await gasClient.getGasPrice();
        setGasPrice(price);
      } catch (e) {
        console.error(e);
      }
      setFetchingGasPrice(false);
    }
    updateGasPrice();
  }, [abi, address, mintAmount, mintArgs, price]);

  const {
    isFetching: isFetchingBatchMintQuota,
    refetch: refetchBatchMintQuota
  } = useContractRead({
    abi: [
      {
        inputs: [],
        name: 'queryBatchMintQuota',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      }
    ],
    functionName: 'queryBatchMintQuota',
    address: currentContractConfig.contractAddress as any,
    enabled: false
  });

  const { config, error: prepareError } = usePrepareContractWrite({
    address: currentContractConfig.contractAddress as `0x${string}`,
    abi,
    functionName: abi[0].name,
    args: mintArgs,
    chainId: targetChain.id,
    enabled: !!gas && !!gasPrice,
    value: parseEther((price * mintAmount).toString(), 'wei'),
    gas,
    gasPrice,
    onError(e) {
      console.error(e);
    }
  });
  const {
    data: writeResult,
    write,
    error,
    isLoading: isWriting
  } = useContractWrite(config);
  const {
    isLoading: isWaitingHash,
    data: txResult,
    error: waitError
  } = useWaitForTransaction({
    hash: writeResult?.hash,
    enabled: !!writeResult?.hash
  });

  const mintedTokenIds: number[] = useMemo(() => {
    // polygon链的logs可能会有多条，需根据合约地址做filter
    const data = txResult?.logs.find(
      (item) =>
        item.address.toLowerCase() ===
          currentContractConfig.contractAddress.toLowerCase() &&
        item.topics[0]?.toLowerCase() ===
          '0x9aaed8bbdadec7577562f58a5615a8002b05c126e88a83a4852d7c69e0a9168d'
    )?.data;
    if (data) {
      const result = AbiCoder.defaultAbiCoder().decode(['uint32[]'], data)[0];
      if (result) {
        return result.map((item: any) => Number(item));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [txResult?.logs]);

  useEffect(() => {
    if (error) {
      toast.error(
        <span className="line-clamp-3">{error.message || 'Mint failed!'}</span>
      );
    }
  }, [error]);

  const {
    refetch: refetchClaimedCount,
    isRefetching: isRefetchingClaimedCount
  } = useContractRead({
    abi: [
      {
        inputs: [],
        name: 'queryClaimedCount',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      }
    ],
    functionName: 'queryClaimedCount',
    address: currentContractConfig.contractAddress as any,
    enabled: false
  });

  useEffect(() => {
    async function handleWaitError() {
      if (!waitError) return;
      if (
        mintType === EMintType.DIRECTLY &&
        waitError.message.toLowerCase().includes('execution reverted')
      ) {
        try {
          const batchMintQuota = (await refetchBatchMintQuota()).data as number;
          console.log('batchMintQuota', batchMintQuota);
          if (batchMintQuota < mintAmount) {
            toast.error(`Insufficient supply! Please lower the batch size.`);
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }
      toast.error(
        <span className="line-clamp-3">
          {waitError.message || 'Failed to obtain transaction results!'}
        </span>
      );
    }
    handleWaitError();
  }, [mintAmount, mintType, refetchBatchMintQuota, waitError]);

  const isMintSuccess = useMemo(() => !!txResult, [txResult]);
  const modalTitle = useMemo(() => {
    if (isMintSuccess) {
      return 'Mint successful!';
    }
    return isMintDirectly ? 'Mint Directly' : 'Play to Mint';
  }, [isMintDirectly, isMintSuccess]);

  useUnmount(() => {
    if (isMintSuccess) {
      setGameMoves([]);
      setPlayMintSignature(undefined);
    }
  });

  useEffect(() => {
    if (isMintSuccess) {
      setGameMoves([]);
    }
  }, [isMintSuccess, setGameMoves]);

  return (
    <Modal
      onClose={() => {
        if (mintType === EMintType.PlAY) {
          setPlayMintSignature(undefined);
        }
        setGameMoves([]);
        navigate(ERouterPaths.LEARN_MORE);
      }}
      className={cn(
        className,
        'sm:mt-[6vh] 3xl:!absolute 3xl:top-1/2 3xl:mt-0 3xl:-translate-y-1/2'
      )}
      title={modalTitle}
    >
      {isMintSuccess ? (
        <MintSuccess
          mintedTokenIds={mintedTokenIds}
          txHash={txResult?.transactionHash || ''}
        />
      ) : (
        <div>
          <ModalLine label="Address" className="mb-[9px]">
            {ellipseAddress(address)}
          </ModalLine>
          <ModalLine label="Network">{targetChain.name}</ModalLine>
          <Split className="my-[14px]" />
          <ModalLine label="Collection">2048.ink</ModalLine>
          <ModalLine label="Amount" className="my-4">
            <AmountInput
              mintAmount={mintAmount}
              setMintAmount={setMintAmount}
            />
          </ModalLine>
          <ModalLine label="Price">
            {currentContractConfig.unitPrice * mintAmount}{' '}
            {currentContractConfig.currency}
          </ModalLine>
          <Button
            disabled={
              isFetchingBatchMintQuota ||
              isFetchingGas ||
              isFetchingGasPrice ||
              isRefetchingClaimedCount
            }
            onClick={async () => {
              try {
                if (isEstimateGasErrorByLowBalance) {
                  toast.error('Insufficient balance!');
                  return;
                }
                if (isFetchingGas || isFetchingGasPrice) {
                  toast.error(
                    'Gas is being estimated, please try again later!'
                  );
                  return;
                }
                if (gas === BigInt(0) || gasPrice === BigInt(0)) {
                  toast.error('Gas estimation error, please try again!');
                  return;
                }
                //pre check claimed count every mint
                const claimedCount = (await refetchClaimedCount())
                  .data as number;
                console.log('claimedCount', claimedCount);
                if (claimedCount === 2000) {
                  toast.error(`Sold out!`);
                  return;
                }
                if (claimedCount + mintAmount > 2000) {
                  toast.error(
                    `Insufficient supply! Please lower the batch size.`
                  );
                  return;
                }
                // pre check batch mint quota
                if (mintType === EMintType.DIRECTLY) {
                  const batchMintQuota = (await refetchBatchMintQuota())
                    .data as number;
                  console.log('batchMintQuota', batchMintQuota);
                  if (batchMintQuota < mintAmount) {
                    toast.error(
                      `Insufficient supply! Please lower the batch size.`
                    );
                    return;
                  }
                }
                if (prepareError) {
                  if (
                    prepareError.message
                      .toLowerCase()
                      .includes('exceeds the balance of the account')
                  ) {
                    toast.error('Insufficient balance!');
                  } else if (
                    prepareError.message
                      .toLowerCase()
                      .includes('exceeds batch mint quota')
                  ) {
                    toast.error(
                      'Insufficient supply! Please lower the batch size.'
                    );
                  } else {
                    toast.error(
                      <span className="line-clamp-3">
                        {prepareError.message}
                      </span>
                    );
                  }
                  return;
                }
                if (!write) {
                  toast.error('Unknown error, please try again later!');
                  return;
                }
                write?.();
              } catch (e: any) {
                const errorMsg =
                  e?.message ||
                  JSON.stringify(e) ||
                  'Unknown error, please try again later!';
                toast.error(<span className="line-clamp-3">{errorMsg}</span>);
                console.error(e);
              }
            }}
            loading={
              isWaitingHash ||
              isWriting ||
              isFetchingBatchMintQuota ||
              isRefetchingClaimedCount
            }
            className="mx-auto mt-6 h-12 w-[231px]"
          >
            Mint
          </Button>
        </div>
      )}
    </Modal>
  );
}
